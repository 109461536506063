import React, { useState } from 'react';
import { Pressable, View } from 'react-native';
// import Animated, { useSharedValue, useAnimatedStyle, Easing, withTiming, withSequence } from 'react-native-reanimated';

import { Image } from '@rugby-au/image';
import { ITheme, useTheme } from '@rugby-au/theme';
import { RxImageProps } from '@rugby-au/image';
import { Text } from '@rugby-au/commons';

import { getFeatureCardStyles } from './styles';

export interface FeatureCardProps {
  id: string;
  appearance: ITheme;
  subTitle?: string;
  blurb?: string;
  title?: string;
  href?: string;
  image: RxImageProps;
  /** Whether the component is called from native file or desktop (browser) version */
  isNative?: boolean;
  isGradient?: boolean;
  quote?: {
    text: string;
    author: string;
  };
}
export const FeatureCard = ({
  // id,
  appearance = 'light',
  subTitle,
  blurb,
  quote,
  title,
  image,
  onCardPress,
  numColumns,
  isNative,
}: FeatureCardProps & { onCardPress: () => void; numColumns: number }) => {
  const [isHover, setIsHover] = useState<null | boolean>(null);

  const { colorPairs, spacing, headings, typography } = useTheme();
  const colors = colorPairs[appearance as keyof typeof colorPairs];
  const styles = getFeatureCardStyles({
    colors,
    spacing,
    // radius,
    headings,
    typography,
    isHover,
    numColumns,
    isNative: isNative ?? true,
  });

  // const offset = useSharedValue(10);
  // const opacity = useSharedValue(1);

  // const animatedStyles = useAnimatedStyle(() => {
  //   return {
  //     transform: [{ translateY: offset.value }],
  //     opacity: opacity.value,
  //     flex: 1,
  //   };
  // });
  // useEffect(() => {
  //   opacity.value = 0;
  //   setTimeout(() => {
  //     offset.value = withSequence(
  //       withTiming(50, {
  //         duration: 100,
  //         easing: Easing.out(Easing.exp),
  //       }),
  //       withTiming(0, {
  //         duration: 1000,
  //         easing: Easing.out(Easing.exp),
  //       }),
  //     );
  //   }, Math.floor(Math.random() * 25));
  //   opacity.value = withTiming(1, { duration: 100, easing: Easing.out(Easing.exp) });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [id]);

  return (
    <Pressable onHoverIn={() => setIsHover(true)} onHoverOut={() => setIsHover(false)} onPress={onCardPress} style={styles.container}>
      {/* <Animated.View style={[animatedStyles, { flex: styles.container.flex }]}> */}
      {/* <View style={[{ flex: styles.container.flex }]}> */}
      <View style={styles.imageContainer}>
        <Image style={styles.imageStyles} url={image.url} alt={image.alt} />
      </View>
      <View style={styles.detailsContainer}>
        {title && (
          <View>
            <Text style={styles.headerText}>{title}</Text>
          </View>
        )}
        {subTitle && (
          <View>
            <Text style={styles.subTitle}>{subTitle}</Text>
          </View>
        )}
        {blurb && (
          <View style={styles.summaryListContainer}>
            <Text style={{ color: colors.foreground?.mid, fontSize: typography.fontSize.xlarge, fontFamily: typography.fontFamily.semiBold }}>{blurb}</Text>
          </View>
        )}
        {quote && (
          <View style={styles.summaryListContainer}>
            <Text style={{ textAlign: 'center' }}>
              <Text style={{ color: colors.foreground?.mid, fontSize: typography.fontSize.xlarge, fontFamily: typography.fontFamily.semiBold, fontStyle: 'italic' }}>
                "{quote?.text}"
              </Text>
              <Text style={{ color: colors.foreground?.mid, fontSize: typography.fontSize.xlarge, fontFamily: typography.fontFamily.semiBold }}>
                {' - '}
                {quote?.author}
              </Text>
            </Text>
          </View>
        )}
      </View>
      {/* </Animated.View> */}
      {/* </View> */}
    </Pressable>
  );
};
