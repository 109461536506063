import React from 'react';
import { View } from 'react-native';

import { useAppConfig } from '@rugby-au/app-config';

import { FeatureCard, FeatureCardProps } from './FeatureCard';
import { useTheme } from '@rugby-au/theme';
import { getFeatureListForDesktop } from './styles';

export const FeatureCardList = ({ list = [], numColumns }: { list: FeatureCardProps[]; numColumns: number }) => {
  const { setNavigation } = useAppConfig();
  const { spacing } = useTheme();

  const { ids, styles } = getFeatureListForDesktop({ spacing, numColumns });
  return (
    <View style={styles.container} dataSet={{ media: ids.container }}>
      {list.map((item, index) => {
        return (
          <FeatureCard
            key={item.id + index}
            id={item.id}
            appearance={item.appearance}
            title={item.title}
            subTitle={item.subTitle}
            image={item.image}
            onCardPress={() => {
              item?.href && setNavigation && setNavigation(item?.href ?? '');
            }}
            numColumns={numColumns}
            isNative={false}
            isGradient={item.isGradient ?? false}
            blurb={item.blurb}
            quote={item.quote}
          />
        );
      })}
    </View>
  );
};
